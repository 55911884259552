import React from "react";
import styled from 'styled-components';
// import { Link } from "gatsby";
import {MAX_MAIN_WIDTH, PAGE_PADDING, responsive} from "../styles/dimens";
import Helmet from "react-helmet";
import {EMAIL_ADDRESS} from "../constants";

const Container = React.memo(styled.main`
  padding: ${PAGE_PADDING};
  margin: auto;
  max-width: ${MAX_MAIN_WIDTH};
  h1 {
    text-align: center;
    ${responsive({
      'phablet-desktop': 'margin-top: 10px;',
      mobile: 'margin-top: 5px'
    })};
  }
  .faq {
    margin-top: 25px;
    .question {
      font-weight: bold
    }
    .answer {
      margin-bottom: 25px;
    }
  }
`);

const AboutPage = React.memo(() => (
  <Container>
    <Helmet>
      <title>Recipe Watch - Privacy Policy</title>
      <meta property="og:title" content='Recipe Watch - Privacy Policy' />
      <meta property="og:type" content="website" />
    </Helmet>
    <h1>Privacy Policy</h1>
    <h2>What personal data do we collect?</h2>
    <div>
      <p>This site has no user accounts and we don't collect any names, addresses,
      or other personally identifying information.
      </p>
      <p>
        We automatically collect certain information when you visit, use or navigate the site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use  site and other technical information. This information is primarily needed to maintain the security and operation of the site, and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and similar technologies.
      </p>
      <p>
        The site is not directed to children nor is it intended to be used by children. We do not knowingly collect, use or disclose any personal data of anyone under the age of 13.
      </p>
      <h2>How we use your information</h2>
      <p>
        Site usage information from logs and analytics are used to optimized overall
        site experience.
      </p>
      <h2>Who your information is shared with</h2>
      <p>
        This site uses Google Analytics to inform site improvements, and
        participates in the Amazon Affiliate Program.
      </p>
      <h2>Do we use cookies and other tracking technologies?</h2>
      <p>
        This site does not use cookies aside from those served by the aforementioned
        analytics and advertising tools.
      </p>
      <h2>How long is personal data kept?</h2>
      <p>
        We will keep your personal data only for the period of time necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by applicable law. The criteria used to determine our retention periods include: (i) the length of time we have an ongoing relationship with you and provide the Site to you; (ii) whether we are subject to a legal obligation; or (iii) whether retention is advisable in light of our legal position.
      </p>
      <h2>How can I contact you about your privacy practices?</h2>
      <p>
        We can be contacted about privacy or anything else at
        {' '} <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
      </p>
    </div>
  </Container>
));

export default AboutPage;
